import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import '@/libs/sweet-alerts'

// Global Components
import './global-components'

import BootstrapVue from 'bootstrap-vue'


var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

//stripe plugin
// import { StripePlugin } from '@vue-stripe/vue-stripe';

// Vue.use(StripePlugin);


// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import form-wizard style
require('@core/assets/fonts/feather/iconfont.css')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
