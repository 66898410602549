<template>
  <nav>
    <div class="container">
      <router-link
        to="/"
        style="background-color: transparent; border-left: none"
      >
        <b-img
          src="@/assets/images/logo/logo.png"
          fluid
          alt="Responsive image"
          width="50"
        ></b-img>
        <!-- <feather-icon
          icon="UserIcon"
          size="35"
          style="color: black"
        ></feather-icon> -->
      </router-link>

      <router-link
        to="/"
        style="
          background-color: transparent;
          border-left: none;
          text-decoration: none;
        "
        ><h1>GINO PANINO</h1></router-link
      >
      <div class="menu">
        <router-link to="/" style="border-left: none" class="dynamic-color"
          >Home</router-link
        >
        <router-link to="/i-nostri-prodotti" style="border-left: none"
          >I nostri prodotti</router-link
        >
        <router-link to="/menu" style="border-left: none">Menù</router-link>
        <!-- <router-link to="/ordina" style="border-left: none"
          >Ordina online</router-link
        > -->
        <!-- <router-link
          :to="{ path: '/', hash: '#WhereID' }"
          v-scroll-to="{ element: '#WhereID', duration: 1000 }"
          style="border-left: none"
          >Dove siamo</router-link
        > -->
        <!-- <router-link
          :to="{ path: '/', hash: '#AboutID' }"
          v-scroll-to="{ element: '#AboutID', duration: 2000 }"
          style="border-left: none"
          >Su di noi</router-link
        > -->
        <!-- <router-link to="/login" style="border-left: none">Accedi</router-link> -->
      </div>
      <button class="hamburger" @click="changecolor()" v-b-toggle.sidebar-1>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <b-sidebar
        id="sidebar-1"
        width="100%"
        no-header
        z-index="-1"
        bg-variant="white"
      >
        <template #default="{ hide }">
          <!-- <nav style="margin-top: 66px; z-index: -1"></nav> -->
          <div class="menunavbar d-flex flex-column">
            <router-link
              to="/"
              class="itemnavbar"
              @click.native="
                changestate();
                hide();
              "
              >Home</router-link
            >

            <!-- <router-link
              :to="{ path: '/', hash: '#WhereID' }"
              v-scroll-to="'#WhereID'"
              class="itemnavbar"
              @click.native="
                changestate();
                hide();
              "
              >Dove siamo</router-link
            >
            <router-link
              :to="{ path: '/', hash: '#AboutID' }"
              v-scroll-to="'#AboutID'"
              class="itemnavbar"
              @click.native="
                changestate();
                hide();
              "
              >Su di noi</router-link
            > -->
            <router-link
              to="/menu"
              class="itemnavbar"
              @click.native="
                changestate();
                hide();
              "
              >Menù</router-link
            >
            <!-- <router-link
              to="/login"
              class="itemnavbar"
              id="profileid"
              @click.native="
                changestate();
                hide();
              "
              >Accedi</router-link
            > -->
            <div style="margin-left: 20px; margin-right: 20px">
              <b-button
                block
                variant="primary"
                style="height: 60px; font-weight: 600; font-size: large"
              >
                <router-link
                  to="/i-nostri-prodotti"
                  class="itemnavbarordina"
                  @click.native="
                    changestate();
                    hide();
                  "
                  >I nostri prodotti</router-link
                >
                <!-- <router-link
                  to="/ordina"
                  class="itemnavbarordina"
                  @click.native="
                    changestate();
                    hide();
                  "
                  >Ordina online</router-link
                > -->
              </b-button>
            </div>
          </div>
        </template>
      </b-sidebar>
    </div>
  </nav>
</template>

<script>
export default {
  components: {},
  mounted() {
    const hamburger = document.querySelector(".hamburger");

    hamburger.addEventListener("click", function () {
      this.classList.toggle("is-active");
    });
    const nav = document.querySelector("nav");
    const white = document.querySelectorAll("a");
    const h1 = document.querySelector("h1");

    window.addEventListener("scroll", function () {
      const offset = window.pageYOffset;

      if (offset > 15) {
        nav.classList.add("scroll");
        h1.classList.add("prova");
        for (const box of white) {
          box.classList.add("prova");
        }
      } else {
        nav.classList.remove("scroll");
        h1.classList.remove("prova");
        for (const box of white) {
          box.classList.remove("prova");
        }
      }
    });
  },
  methods: {
    changestate() {
      document.querySelector(".hamburger").classList.remove("is-active");
      const h1 = document.querySelector("h1");
      h1.classList.toggle("black");
    },
    changecolor() {
      const h1 = document.querySelector("h1");
      h1.classList.toggle("black");
    },
  },
};
</script>

<style scoped>
.scroll {
  padding: 10px;
  background-color: #054ab2;
  border-bottom: 3px solid #ffb31b;
  position: fixed;
  z-index: 100 !important;
}
.prova {
  color: white !important;
  z-index: 100;
}

.black {
  color: black !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "amable", sans-serif;
  margin-top: 11px;
  margin-left: 5px;
  color: black;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 10px 32px;
  transition: all 0.6s;
}
.x {
  color: white !important;
}

.hamburger {
  display: block;
  position: relative;
  z-index: 100;
  user-select: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background-color: black;
  border-radius: 6px;
  z-index: 100;
  transform-origin: 0 0;
  transition: 0.4s;
}

/* .hamburger:hover span:nth-child(2) {
  transform: translateX(10px);
  background-color: #ffb31b;
} */

.hamburger.is-active span:nth-child(1) {
  transform: translate(0px, -2px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(15px);
}

.hamburger.is-active span:nth-child(3) {
  transform: translate(-3px, 3px) rotate(-45deg);
}

/* .hamburger.is-active:hover span {
  background-color: #ffb31b;
} */

.menu {
  display: none;
  flex: 1 1 0%;
  justify-content: flex-end;
  margin: 0 -16px;
  text-align: right;
}

.menu a {
  margin: 0 5px;
  font-weight: 600;
  text-decoration: none;
  transition: 0.4s;
  padding: 8px 16px;
  border-radius: 99px;
  z-index: 100;
  color: black;
}

.menu a.is-active,
.menu a:hover {
  background-color: #ffb31b;
}

.router-link-exact-active {
  background-color: #ffb31b;
  /* border-left: 6px solid #054ab2; */
}

@media (min-width: 991px) {
  .hamburger {
    display: none;
  }

  .menu {
    display: block;
  }

  .nav {
    padding: 16px 100px;
  }
}

.menunavbar {
  margin-top: 85px;
  display: block;
  font-size: large;
  text-align: center;
}

.itemnavbar {
  padding: 20px 16px;
  text-decoration: none;
  color: black !important;
  border-block-color: black;
  background-color: white;
  font-weight: 600;
  margin: 2px;
}
.itemnavbarordina {
  text-decoration: none;
  color: white !important;
  font-weight: 600;
  background-color: var(--blu);
  margin: 2px;
}
.itemnavbar:active {
  transform: translateY(4px);
}
</style>
