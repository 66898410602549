import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/menu',
      name: 'menu-page',
      component: () => import('@/views/Menu.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/i-nostri-prodotti',
      name: 'menu-page',
      component: () => import('@/views/NostriProdotti.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/ordina',
    //   name: 'ordina-page',
    //   component: () => import('@/views/Ordina.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/success',
    //   name: 'success-page',
    //   component: () => import('@/components/ordina/SuccessPage.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/failed',
    //   name: 'failed-page',
    //   component: () => import('@/components/ordina/FailedPage.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/dovesiamo',
    //   name: 'dove-page',
    //   component: () => import('@/views/DoveSiamo.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/about',
    //   name: 'about-page',
    //   component: () => import('@/views/About.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/profilo',
    //   name: 'profilo-page',
    //   component: () => import('@/views/Profilo.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: () => import('@/components/login/Register.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/resetpassword',
    //   name: 'resetpassword',
    //   component: () => import('@/components/login/ResetPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/forgotpassword',
    //   name: 'forgotpassword',
    //   component: () => import('@/components/login/ForgotPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
