import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  state: {
    counter: 0,
    order: ''
    ,
    popupstate: 0
  },
  mutations: {
    increment(state) {
      state.counter = state.counter + 1;
    },
    incrementpopup(state) {
      state.popupstate = state.popupstate + 1;
    },
    decrement(state) {
      state.counter = state.counter - 1;
    },
    copyorder(state, order) {
      state.order = order;
    }
  },
  getters: {
    counter: state => state.counter,
    order: state => state.order,
    popupstate: state => state.popupstate,
  }
})
